import React  from 'react'
import {Link} from 'react-router-dom'

import {agendaData} from '../assets/data/AGENDA.js'
import {articleData} from '../assets/data/ARTICLES.js'
import {transitionData} from '../assets/data/Transition.js'
import {montageData} from '../assets/data/Montage.js';
import {postHumanData} from '../assets/data/PostHuman.js';
import { woodblocksData } from '../assets/data/Woodblocks.js';
import { twoTowersData } from '../assets/data/TwoTowers.js';
import { nanData } from '../assets/data/Nan.js';
import { wwwData } from '../assets/data/WWW.js';


import Filter from '../components/Filter.jsx';

let visibleOne = 0;



function chronologicalOrder(){
  //SORT DIV'S CHRONOLOGICAL!
  let i =0;
  var mylist = document.getElementById('myListDiv');
  var divs = document.getElementsByClassName("archive__content");
  var listitems = [];
  for (i = 0; i < divs.length; i++) {
    listitems.push(divs.item(i));
  }
  listitems.sort(function(a, b) {
    var compA = a.getAttribute('id');
    var compB = b.getAttribute('id');
    return (compA < compB) ? -1 : (compA > compB) ? 1 : 0;
  });
  for (i = 0; i < listitems.length; i++) {
    mylist.appendChild(listitems[i]);
  }; 

}

function filterPopUp(){
  visibleOne += 1
  
  const filterButton = document.getElementById('filter__button');
  const filterContainer = document.getElementById('filter__wrapper');

  
  if(visibleOne === 1){
    filterContainer.style.display = 'block';
    filterButton.style.borderColor = "lightgray"
    filterButton.style.color = "lightgray";

    visibleOne = -1

  }else{
    filterContainer.style.display = 'none';
    filterButton.style.borderColor = "black"
    filterButton.style.color = "black"

  }
}

function Archivepage() {
  
  if(!articleData) return <div className='loading'>Loading...</div>;

  return (
    <div className='archive' onLoad={chronologicalOrder}>
      <div className='archive__container'>
        <div className='archive__container__title editH1'>
          Archive
        </div>
        <div className='archive__container__content' id='myListDiv'> 
              <div className={`archive__content archive__content__archiveDate content__archiveDate1`} id={`2022-01-01`}>
                <h4 className='editH1'>2022</h4>
              </div>
              <div className={`archive__content archive__content__archiveDate content__archiveDate2`} id={`2023-01-01`}>
                <h4 className='editH1'>2023</h4>
              </div>
              <div className={`archive__content archive__content__archiveDate content__archiveDate3`} id={`2024-01-01`}>
                <h4 className='editH1'>2024</h4>
              </div>
              <div className={`archive__content archive__content__archiveDate content__archiveDate3`} id={`2025-01-01`}>
                <h4 className='editH1'>2025</h4>
              </div>
              {/* <div className={`archive__content archive__content__archiveDate content__archiveDate3`} id={`2026-01-01`}>
                <h4 className='editH1'>2026</h4>
              </div>
              <div className={`archive__content archive__content__archiveDate content__archiveDate3`} id={`2027-01-01`}>
                <h4 className='editH1'>2027</h4>
              </div>
              <div className={`archive__content archive__content__archiveDate content__archiveDate3`} id={`2028-01-01`}>
                <h4 className='editH1'>2028</h4>
              </div>
              <div className={`archive__content archive__content__archiveDate content__archiveDate3`} id={`2029-01-01`}>
                <h4 className='editH1'>2029</h4>
              </div> */}
          { 
            articleData && articleData.map((a, index) => (
              <div className={`archive__content archive__content__article content__article${index}`} id={`${a.order}`} style={{display: (a.titlePartTwo ? 'block':'none')}}>
                <Link to={"/" + a.slug} key={a.slug}>
                  <img src={a.imageURL}  className={`content__article__img content__article__img${index}`} alt="" />
                  <h1 className='editH1'>{a.titlePartOne} {a.titlePartTwo}</h1>
                  <h2 className='editH2'>{a.author} | {a.publishedAt}</h2>
                </Link>
              </div>
            ))
          }
          {
            agendaData && agendaData.map((a, index) => (
              <div className={`archive__content archive__content__agenda content__agenda${index}`} id={`${a.order}`} style={{display: (a.srcFront ? 'block':'none')}}>
                <a href={a.link} target="_blank" rel="noreferrer"><img src={a.srcFront}  className={`content__agenda__img content__agenda__img${index}`} alt="" /></a>
              </div>
            ))
          }
          {
            transitionData && transitionData.map((s, index) => (
              <div className={`archive__content archive__content__slider content__slider${index}`} id={`${s.order}`} style={{display: (s.src ? 'block':'none')}}>
                <Link to={"/" + s.slug} key={s.slug}>
                  <img src={s.src}  className={`content__slider__img content__slider__img${index}`} alt="" />
                </Link>
              </div>
            ))
          }

          {
            montageData && montageData.map((s, index) => (
              <div className={`archive__content archive__content__slider content__slider${index}`} id={`${s.order}`} style={{display: (s.src ? 'block':'none')}}>
                <Link to={"/" + s.slug} key={s.slug}>
                  <img src={s.src}  className={`content__slider__img content__slider__img${index}`} alt="" />
                </Link>
              </div>
            ))
          }

          {
            postHumanData && postHumanData.map((s, index) => (
              <div className={`archive__content archive__content__slider content__slider${index}`} id={`${s.order}`} style={{display: (s.src ? 'block':'none')}}>
                <Link to={"/" + s.slug} key={s.slug}>
                  <img src={s.src}  className={`content__slider__img content__slider__img${index}`} alt="" />
                </Link>
              </div>
            ))
          }
          {
            woodblocksData && woodblocksData.map((s, index) => (
              <div className={`archive__content archive__content__slider content__slider${index}`} id={`${s.order}`} style={{display: (s.src ? 'block':'none')}}>
                <Link to={"/" + s.slug} key={s.slug}>
                  <img src={s.src}  className={`content__slider__img content__slider__img${index}`} alt="" />
                </Link>
              </div>
            ))
          }
          {
            twoTowersData && twoTowersData.map((s, index) => (
              <div className={`archive__content archive__content__slider content__slider${index}`} id={`${s.order}`} style={{display: (s.src ? 'block':'none')}}>
                <Link to={"/" + s.slug} key={s.slug}>
                  <img src={s.src}  className={`content__slider__img content__slider__img${index}`} alt="" />
                </Link>
              </div>
            ))
            
          }
          {
            nanData && nanData.map((s, index) => (
              <div className={`archive__content archive__content__slider content__slider${index}`} id={`${s.order}`} style={{display: (s.src ? 'block':'none')}}>
                <Link to={"/" + s.slug} key={s.slug}>
                  <img src={s.src}  className={`content__slider__img content__slider__img${index}`} alt="" />
                </Link>
              </div>
            ))
            
          }
          {
            wwwData && wwwData.map((s, index) => (
              <div className={`archive__content archive__content__slider content__slider${index}`} id={`${s.order}`} style={{display: (s.src ? 'block':'none')}}>
                <Link to={"/" + s.slug} key={s.slug}>
                  <img src={s.src}  className={`content__slider__img content__slider__img${index}`} alt="" />
                </Link>
              </div>
            ))
            
          }

          {/* {
            allArchive.renders && allArchive.renders.map((r, index) => (
                <div className={`archive__content archive__content__renders content__renders${index}`} id={`${r.order}`} style={{display: (r.renderURL ? 'block':'none')}}>
                  <Link to={"/" + r.slug.current} key={r.slug.current}>
                    <img src={r.renderURL}  className={`content__renders__img content__renders__img${index}`} alt="" />
                  </Link>
                </div>
            ))
          } */}
          {/* {
            allArchive.opencall && allArchive.opencall.map((o, index) => (
              <div className={`archive__content archive__content__opencall content__opencall${index}`} id={`${o.order}`} style={{display: (o.imageURL ? 'block':'none')}}>
                <a href={o.link} target="_blank" rel="noreferrer"><img src={o.imageURL}  className={`content__opencall__img content__opencall__img${index}`} alt="" /></a>
              </div>
            ))
          } */}

          
        </div>
        <div className='filter__container'>
          <div className='filter__button' id='filter__button' onClick={filterPopUp}>
            <h1>f</h1>
          </div>
          <Filter/>
        </div>
      </div>

    </div>
  )
}

export default Archivepage