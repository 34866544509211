import React from 'react'

import {Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import video from '../../assets/videos/articles/WWW/www-pc2.mp4';
import img1 from '../../assets/images/articles/WWW/www-02.jpg'
import img2 from '../../assets/images/articles/WWW/www-08.jpg'
import img3 from '../../assets/images/articles/WWW/www-30.png'
import img4 from '../../assets/images/articles/WWW/www-05.jpg'
import img5 from '../../assets/images/articles/WWW/www-36.jpg'
import img6 from '../../assets/images/articles/WWW/www-32.jpg'
import img7 from '../../assets/images/articles/WWW/www-33.jpg'
// import img8 from '../../assets/images/articles/WWW/www-05.jpg'
import img9 from '../../assets/images/articles/WWW/www-13.jpg'
import img10 from '../../assets/images/articles/WWW/www-34.jpg'
import img11 from '../../assets/images/articles/WWW/www-12.jpg'
// import img12 from '../../assets/images/articles/WWW/www-05.jpg'
import img13 from '../../assets/images/articles/WWW/www-18.jpg'
import img14 from '../../assets/images/articles/WWW/www-19.jpg'
import img15 from '../../assets/images/articles/WWW/www-20.jpg'
import img16 from '../../assets/images/articles/WWW/www-21.jpg'

import downloadArticle from '../../assets/pdf/www-article.pdf';



const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid black',
    borderRadius: '1px',
  },
}));


function WWW() {
  return (
    <div className='www' id='background'>
      <div className='www__backgroundVideo'>
        <div className='www__backgroundVideo__wrapper'>
            <div className='www__backgroundVideo__title'>
                <h1 className='editH1'>Design the playground: <br />a matter of collaboration</h1>
                <h2 className='editH2'>Cosima Rosie Lagae & Lars Marcoen | 04.03.2025</h2>
            </div>
        </div>
        <video src={video} width="100%" height="auto" loop autoPlay muted playsInline></video>
      </div>


      <div className='www__container'>
        <div className='www__container__title'>
          <h1 className='editH1'>Design the playground: <br />a matter of collaboration</h1>
          <h2 className='editH2'>Cosima Rosie Lagae & Lars Marcoen | 04.03.2025</h2>
        </div>

        <div className='www__container__intro'>
          <div className='www__intro__img'>
            <img src={img1} alt="" />
            <p className='credit__margin'> @Cosima Rosie Lagae </p>
          </div>

          <div className='www__intro__text'>
            From a general perspective, it can be observed that most of the Web sites on the World Wide Web prioritize advertising, efficiency and profit over the needs of users. Therefore, now more than ever, we need individuals or collectives with websites to return the network to its original structure. According to Laurel Schwulst (2020), we need a direction driven by impactful individuals, such as artists. From a general perspective, it can be observed that most of the websites are designed by creative minds who develop their own worlds.
            <br /><br />
            By “reforming” digital design norms, we find space to explore new possibilities. There is room to create surprises and dive into the unexplored. By trying to refuse existing guidelines, we can collectively design new spaces within which new stories can emerge.
            Within this research, we are looking at how a web developer can collaborate with one or more artists or other creative minds. By organizing collective workshops within Club Dérive, the quest to design their own website begins. Club Dérive is an open-network that develops scenography, claims spaces and researches nightlife based on the Situationists' theoretical framework. 
            <br /><br />
            The result should not be just an immersive and playful website, but a living workshop format that can be applied to other creative collectives, institutes or networks. 
          </div>
        </div>

        <div className="www__container__body">
          <div className='www__body__text__quote'>
              <h2>“We prefer to think of websites as living, ever-changing, temporary spaces”</h2>
              <p>- Laurel Schwulst</p>
          </div>
        </div>

        <div className="www__container__body" >
          <div className='www__body__text' >
            <h2  id='get__H2'>
              RELEVANCE
            </h2>

            <div>
              Although at first glance they seem accommodating and convenient for users, universally popular social media sites such as Facebook, Instagram, Snapchat and Pinterest are companies that prioritize advertising and profits over the needs of their users, argues Laurel Schwulst (2020). It is common to feel anxiety when using social media and certain websites because their developers do not prioritize the needs or happiness of users.  
              <br /><br />
              To guide the future of the Web today we need individuals and collectives more than ever. The Web is called the Web because its vitality depends on what the definition says: “an interconnected web of individual nodes that bring a vast network to life. This web should work for its users rather than be driven by a handful of large corporations such as Facebook, Instagram, Twitter and Google.<HtmlTooltip title="Schwulst, L. S. (2018, 21 mei). My website is a shifting house next to a river of knowledge. What could yours be? thecreativeindependent.com. https://thecreativeindependent.com/essays/laurel-schwulst-my-website-is-a-shifting-house-next-to-a-river-of-knowledge-what-could-yours-be/"><sup className='tooltip_ref'>1</sup></HtmlTooltip>
              <br /><br />
              Berners Lee, founder of the “World Wide Web” in 1989, wants to make sure that this Web we all connect to really works for us, as individuals: “I want to challenge all of us to have greater aspirations for the Web. I want the Web to reflect our hopes and fulfill our dreams, rather than increase our fears and deepen our divisions.”<HtmlTooltip title="Berners-Lee, T., & Fischetti, M. (1999a). Weaving the Web: The Past, Present and Future of the World Wide Web by Its Inventor."><sup className='tooltip_ref'>2</sup></HtmlTooltip>
            </div>
          </div>
          <div className='www__body__image'>
              <img src={img2} alt=""  />
          </div>
        </div>

        <div className='www__container__bigImg'>
          <img className='www__big__img' src={img3} alt="" />
          <p className='credit__margin'> @Cosima Rosie Lagae </p>
        </div>

        <div className="www__container__body">
            <div className='www__body__text__middle' id='get__H2'>
                <h2>I. BACK TO WWW</h2>
                  By having websites of their own, individuals can return the Web to its original structure. There is a need for direction, a direction driven by impactful individuals, such as artists, among others. They are creative minds who develop their own world (world-building), consisting of everything; not only known worldly elements, but also everything around it such as the rules, style, language and architecture. They do this first for themselves, and later for others in order to display their own world to the outside world.<HtmlTooltip title="1. Schwulst, L. S. (2018, 21 mei). My website is a shifting house next to a river of knowledge. What could yours be? thecreativeindependent.com. https://thecreativeindependent.com/essays/laurel-schwulst-my-website-is-a-shifting-house-next-to-a-river-of-knowledge-what-could-yours-be/"><sup className='tooltip_ref'>1</sup></HtmlTooltip> 
                  <br /><br />
                  Individuals can steer the web back to its original architecture simply by having a website. Artists, in particular, could be instrumental in this space, showing the world where the web can go.
                  <br /><br />
                  This is why websites are so important. Laurel Schwulst compares the vocabulary of websites to architecture, not architecturally but rather the various spaces or objects in a house. Web sites allow the author to create not only works (the “objects”) but also the world (the rooms, the layout of the rooms, the architecture). Ideally, the two inform each other in a self-reinforcing loop. Which can be incredibly conducive to an artist's practice.<HtmlTooltip title="Schwulst, L. S. (2018, 21 mei). My website is a shifting house next to a river of knowledge. What could yours be? thecreativeindependent.com. https://thecreativeindependent.com/essays/laurel-schwulst-my-website-is-a-shifting-house-next-to-a-river-of-knowledge-what-could-yours-be/"><sup className='tooltip_ref'>1</sup></HtmlTooltip>
                  <br /><br />
                  A Web site can be anything. It doesn't (and probably shouldn't) be an archive of an artist's complete body of work. That is dead as soon as it is published. A website, or anything else interactive, is inherently unfinished. It is imperfect. We prefer to think of websites as living, ever-changing, temporary spaces.<HtmlTooltip title="Schwulst, L. S. (2018, 21 mei). My website is a shifting house next to a river of knowledge. What could yours be? thecreativeindependent.com. https://thecreativeindependent.com/essays/laurel-schwulst-my-website-is-a-shifting-house-next-to-a-river-of-knowledge-what-could-yours-be/"><sup className='tooltip_ref'>1</sup></HtmlTooltip>
                  <br /><br />
                  With recent developments in Web technology, “digital” has become synonymous with accessibility. Design standards have been established, focusing on “best practices for user experience” and “seamless interactions”.<HtmlTooltip title="Apple Human Interface Guidelines: The Apple Desktop Interface. (1987). Inc, A. C. (1987). Apple Human Interface Guidelines: The Apple Desktop Interface. Addison Wesley Publishing Company."><sup className='tooltip_ref'>3</sup></HtmlTooltip> While this approach serves efficiency, it strips the online world of raw emotion and unique experiences.
                  <br /><br />
                  For many in the design world, these guidelines have taken away the ability to invent new forms and uses. For users, they have led to an emotionless experience when these methods, intended to elicit addiction, are fueled by a misuse of human behavioral studies.<HtmlTooltip title="Eyal, N. (2014). Hooked: How to Build Habit-Forming Products. Penguin."><sup className='tooltip_ref'>4</sup></HtmlTooltip>
                  <br /><br />
                  'Seamless interfaces' may be necessary when interacting with some services and tools, but striving for effectiveness should not be the only way to design. What would happen if we were left in a barren world, without any opportunity for creativity and experimentation? If we navigate a space that is not designed to provoke thought, how can one experience anything or feel any emotion?<HtmlTooltip title="Krug, S. (2013). Don’t Make Me Think, Revisited: A Common Sense Approach to Web Usability. New Riders."><sup className='tooltip_ref'>5</sup></HtmlTooltip>
                  <br /><br />
                  By “reshaping” digital design standards, we find space to explore new possibilities. We create surprise and dive into the unexplored. By refusing existing guidelines, we design new spaces within which new stories can emerge. Stories where people take the time to discover and understand them. In this way, we create a relationship with our users that we welcome, rather than building a flow to lead emotionless users.<HtmlTooltip title="Dvtk. (2023, February 8). Positive Friction. DVTK →↓↑←. https://dvtk.us/lexicon/positive-friction"><sup className='tooltip_ref'>6</sup></HtmlTooltip>
            </div>
        </div>

        <div className="www__container__body" >
          <div className='www__body__text' >
            <h2  id='get__H2'>
              II. WWWHAT IS CLUB DERIVE (with Lars Marcoen)
            </h2>

            <div>
              <i><b>What is Club Dérive?</b></i> <br />
              Club Dérive is committed to radical and experimental club research. The focus of this design and architectural research is the interaction between the environment and the clubgoer. The output of this research mainly translates into three pillars: experimental club scenography, participatory workshops with a focus on co-ownership of the project, and developing new venues for all of this to go on.
              <br /><br />
              The research builds on pre-existing situationist theories by Guy Debord, Constant Nieuwenhuys and others. Situationists are experts in atmospheres, environments and spaces and are developing the “science” of psychogeography.
              <br /><br />
              <i><b>Who are the situationists and what did they develop?</b></i> <br />
              In 1956, the French thinker Guy Debord formulated theories under the names Détournement and Dérive from an important critique of modernist urbanism, which was too concerned with control and the framing of social life. Residential neighborhoods with rationally demarcated and separated zones for functions such as living, working and leisure and a purely functional street pattern lead to isolation of the individual and a decrease in social encounters. The potential for unplanned conversation and encounter was suppressed. Guy Debord used Détournement as an urbane strategy to expose paradoxes of society, in this way he aimed at a mental transformation that led to a resolution of the colonization that reigns over everyday life in the city.<HtmlTooltip title="Hanssen, L. (z.d.). psychogeografica. Léon Hanssen. https://leonhanssen.nl/psychogeografica/psychogeografica. (z.d.). Léon Hanssen. https://leonhanssen.nl/psychogeografica/"><sup className='tooltip_ref'>7</sup></HtmlTooltip>
              <br /><br />
              <i><b>What is (the tactic) Dérive?</b></i> <br />
              Debord described the “dérive” as a wandering through changing environments and atmospheres of the city, in which man joyfully surrenders to the stimulations and emotional effects of whatever he happens to encounter on his journey. The spatial patterns and effects of such wanderings were mapped by the discipline of what he called psychogeography. By following a set of rules, wanderers were guided by the feelings a place caused, rather than by following a predetermined route or the shortest path between two points. Thus, the city was divided into spheres with different emotions in an arbitrary succession. Fixed routines of movement were broken and replaced by spontaneous movements under the influence of the environment.<HtmlTooltip title="Debord, G. (1958). Théorie de la dérive. Internationale Situationniste, 1958(2)."><sup className='tooltip_ref'>8</sup></HtmlTooltip>
              <br /><br />
              <i><b>Was this ever materially realized?</b></i>  <br />
              Starting in 1956, visual artist Constant Nieuwenhuys worked, for the first few years together with Guy Debord, on New Babylon, a utopian futuristic model for the city as a new form of society. He worked for 20 years on a large amount of paintings, drawings, maps, texts and models. The idea was that increasing industrialization and automation would give man a lot of free time, and for this playing man Constant designed a continuously changing play space with labyrinth-shaped spaces with movable walls, stairs and bridges. His New Babylon city consists of a network of interconnected sectors that respond to the psycho-emotional states of its wanderers. New Babylon was a delirium of public space.<HtmlTooltip title="Nieuwenhuys, T., Stamps, L., McDonnell, S., Haags Gemeentemuseum, Hatje Cantz Verlag, Museo Nacional Centro de Arte Reina Sofía, & Cobra Museum Voor Moderne Kunst (Amstelveen, N. (2016). Constant: New Babylon. Beltz Verlag."><sup className='tooltip_ref'>9</sup></HtmlTooltip>
              <br /><br />
              <i><b>Why and what does Club Dérive need a website for?</b></i> <br />
              We would like to frame our past and future projects. All the experience and knowledge gathered by Club Dérive is meant to be shared. Each project ideally ends in a form of publication with the aim to share the process. Our website should serve as an open-source communication platform to share our operation and research. In addition, all our participants, contributors and participants are of great value, so we would like to announce them in an honorable way on our website. But there might be even more possibilities... 
            </div>
          </div>
          <div className='www__body__image'>
              <img src={img4} alt=""  />
          </div>
        </div>

        <div className="www__container__body www__workshop__container">

          <img className='www__workshop__images www__workshop__images__1' src={img5} alt=""  />
          <img className='www__workshop__images www__workshop__images__2' src={img6} alt=""  />
          <img className='www__workshop__images www__workshop__images__3' src={img7} alt=""  />
          {/* <img className='www__workshop__images www__workshop__images__4' src={img8} alt=""  /> */}
          <img className='www__workshop__images www__workshop__images__5' src={img9} alt=""  />
          <img className='www__workshop__images www__workshop__images__6' src={img10} alt=""  />
          <img className='www__workshop__images www__workshop__images__7' src={img11} alt=""  />
          {/* <img className='www__workshop__images www__workshop__images__8' src={img12} alt=""  /> */}

          <div className='www__container__workshop'>
            <h2 className='www__workshop__h2'>III. WWWORKSHOP </h2>
            <div className='www__body__text__workshop' id='get__H2'>
              <div className='www__workshop__subtitle'>
                <div className='www__workshop__subtext'>
                  When working in a collective manner, it is beneficial to create a framework that provides direction toward the desired outcome. The trick is to determine the amount of breathing room. It is necessary to leave enough slack for spontaneous development and free interpretation; on the other hand, an abundance of space can also fail to produce results. The tighter the framework, the more effective the results within it. As well as the freedom to color outside the lines.
                </div>

              Inspect & Analyze
                <div className='www__workshop__subtext'>
                  The first exercise, “Inspect and Analyze,” focused on already existing websites. A framework of all kinds of websites had been prepared in advance. The participants had to individually examine these websites with certain questions in their minds: “What do you notice?”, “What attracts you?”, “What pages are present?”, “What (inter)actions do you take?”, “How are you taken by the website?”, “What is your route?”...
                </div>

              SPACE & ACTION
                <div className='www__workshop__subtext'>
                  The second exercise, “Space and Action,” involved collective effort. A mind map was constructed in which the different necessary pages (or spaces) were noted. Once these were in place, the connections would be added. “Which page is linked to which one?” and ‘What flow is created?’. With each connection, consideration was also given to what action will occur. A click, hover, drag or scroll? 
                </div>

              INVENT THE FUNCTIONS 
                <div className='www__workshop__subtext'>
                  With this mind map in mind, the third and final exercise of the day, “Invent the functions,” was designed. Here the participants worked individually again. Each person chose a page to design its functions. One did not yet consider the visual dressing of the page, but rather the skeleton. How will the user interact with the page? What path should it take? 
                </div>

              DESIGN THE PLAYGROUND
                <div className='www__workshop__subtext'>
                  In “Design the playground,” the idea was for participants to actually design the website. They thought about color, typefaces... The emphasis was placed on being as open source as possible. For example, choosing typefaces that are open source. In one day we could not design a whole website, but strong first concepts could already be produced. 
                </div>

                <img className='www__workshop__subtext__img www__workshop__subtext__img1' src={img13} alt="" />
                <img className='www__workshop__subtext__img www__workshop__subtext__img2' src={img14} alt="" />
                <img className='www__workshop__subtext__img www__workshop__subtext__img3' src={img15} alt="" />
                <img className='www__workshop__subtext__img www__workshop__subtext__img4' src={img16} alt="" />
              </div>
            </div>
          </div>
        </div>


        <div className="www__container__body">
          <div className='www__body__text__quote'>
              <h2>The result… the website you are surfing on RIGHT NOW! Go and take a look, go and get your own website, just go. </h2>
          </div>
        </div>

        {/* <div className="www__container__body">
            <div className='www__body__text__middle' id='get__H2'>
                <h2>The result… the website you are surfing on RIGHT NOW! Go and take a look, go and get your own website, just go. </h2>
            </div>
        </div> */}

        <div className="www__container__body">
            <div className='www__body__text__middle' id='get__H2'>
              Written by Cosima Rosie Lagae & Lars Marcoen, published by Club Dérive with eternal gratitude towards the participants of the workshop: <br /><br />
              Julie Vanlook <br />
              Minne Piot <br />
              Joram De Cocker <br />
            </div>
        </div>

        <div className="www__container__body">
          <div className='www__body__text__middle middle__center' id='get__H2'>
            <div className='display__flex'>
              <div className='article__download__button'>
                <a href={downloadArticle} download="article_Design-the-Playground_Cosima-Rosie-Lagae_Lars-Marcoen">Download Article</a>
              </div>
            </div>
          </div>
        </div>

        <div className="www__container__body">
          <div className='www__body__text__middle' id='get__H2'>
            <h2>Sources</h2>
              Schwulst, L. (2020). My website is a shifting house next to a river of knowledge. What could yours be? Thecreativeindependent. https://thecreativeindependent.com/essays/laurel-schwulst-my-website-is-a-shifting-house-next-to-a-river-of-knowledge-what-could-yours-be/ 
              <br /><br />
              Berners-Lee, T. (2004). Weaving the Web: The Original Design and Ultimate Destiny of the World Wide Web by Its Inventor. Turtleback Books.
              <br /><br />
              Apple Human Interface Guidelines: The Apple Desktop Interface. (1987). Inc, A. C. (1987). Apple Human Interface Guidelines: The Apple Desktop Interface. Addison Wesley Publishing Company.
              <br /><br />
              Krug, S. (2013). Don’t Make Me Think, Revisited: A Common Sense Approach to Web Usability. New Riders.
              <br /><br />
              Eyal, N. (2014). Hooked: How to Build Habit-Forming Products. Penguin.
              <br /><br />
              Dvtk. (2023, February 8). Positive Friction. DVTK →↓↑←. https://dvtk.us/lexicon/positive-friction
              <br /><br />
              Hanssen, L. (z.d.). psychogeografica. Léon Hanssen. https://leonhanssen.nl/psychogeografica/
              psychogeografica. (z.d.). Léon Hanssen. https://leonhanssen.nl/psychogeografica/
              <br /><br />
              Debord, G. (1958). Théorie de la dérive. Internationale Situationniste, 1958(2).
              <br /><br />
              Nieuwenhuys, T., Stamps, L., McDonnell, S., Haags Gemeentemuseum, Hatje Cantz Verlag, Museo Nacional Centro de Arte Reina Sofía, & Cobra Museum Voor Moderne Kunst (Amstelveen, N. (2016). Constant: New Babylon. Beltz Verlag.
          </div>
        </div>


      </div>
    </div>
  )
}

export default WWW