export const wwwData = [
    {
        src: require("../images/articles/WWW/www-02.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-08.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-30.png"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-05.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-36.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-32.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-33.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-13.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-34.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-12.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-18.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-19.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-20.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      },
      {
        src: require("../images/articles/WWW/www-21.jpg"),
        publishedAt: "2025-03-04",
        author: "Cosima Rosie Lagae & Lars Marcoen",
        slug: "www",
        category: "article",
        order: "2025-03-04"
      }
                              
]
