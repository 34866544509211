import React from 'react';

import {Tooltip, tooltipClasses } from '@mui/material';
import { styled } from '@mui/material/styles';

import { Swiper, SwiperSlide } from 'swiper/react';
import {Navigation, Pagination, Scrollbar, A11y, Keyboard, Mousewheel, EffectCreative, EffectCards } from 'swiper';
import 'swiper/css';
import 'swiper/css/navigation';


import video from '../../assets/videos/articles/toolkit/IMG_1030.mp4';
import video2 from '../../assets/videos/articles/toolkit/IMG_1030_1.mp4';
import img1 from '../../assets/images/articles/toolkit/toolkit-06.jpg';
import img2 from '../../assets/images/articles/toolkit/toolkit-01.jpg';
import img3 from '../../assets/images/articles/toolkit/toolkit_slide-01.jpg';
import img4 from '../../assets/images/articles/toolkit/toolkit_slide-02.jpg';
import img5 from '../../assets/images/articles/toolkit/toolkit_slide-03.jpg';
import img6 from '../../assets/images/articles/toolkit/toolkit_slide-04.jpg';
import img7 from '../../assets/images/articles/toolkit/toolkit_slide-05.jpg';
import img8 from '../../assets/images/articles/toolkit/toolkit_slide-06.jpg';
import img9 from '../../assets/images/articles/toolkit/toolkit_slide-07.jpg';
import img10 from '../../assets/images/articles/toolkit/toolkit_slide-08.jpg';
import img11 from '../../assets/images/articles/toolkit/toolkit_slide-09.jpg';
import img12 from '../../assets/images/articles/toolkit/toolkit_slide-10.jpg';
import img13 from '../../assets/images/articles/toolkit/toolkit_slide-11.jpg';
import img14 from '../../assets/images/articles/toolkit/toolkit_slide-12.jpg';
import img15 from '../../assets/images/articles/toolkit/toolkit_slide-13.jpg';
import img16 from '../../assets/images/articles/toolkit/toolkit_slide-14.jpg';
import img17 from '../../assets/images/articles/toolkit/toolkit_slide-15.jpg';
import img18 from '../../assets/images/articles/toolkit/toolkit_slide-16.jpg';
import img19 from '../../assets/images/articles/toolkit/toolkit_slide-17.jpg';
import img20 from '../../assets/images/articles/toolkit/toolkit_slide-18.jpg';
import img21 from '../../assets/images/articles/toolkit/toolkit_slide-19.jpg';
import img22 from '../../assets/images/articles/toolkit/toolkit_slide-20.jpg';
import img23 from '../../assets/images/articles/toolkit/toolkit-07.jpg';
import img24 from '../../assets/images/articles/toolkit/toolkit-08.jpg';
import img25 from '../../assets/images/articles/toolkit/toolkit-10.jpg';
import img26 from '../../assets/images/articles/toolkit/toolkit-09.jpg';
import img27 from '../../assets/images/articles/toolkit/toolkit-03.jpg';
import img28 from '../../assets/images/articles/toolkit/toolkit-05.jpg';
import img29 from '../../assets/images/articles/toolkit/toolkit-04.jpg';


import downloadArticle from '../../assets/pdf/montage_take-two-give-one.pdf';

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'white',
    color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 400,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid black',
    borderRadius: '1px',
  },
}));


function Toolkit() {
  return (
    <div className='toolkit' id='background'>
        <div className='toolkit__backgroundVideo'>
            <div className='toolkit__backgroundVideo__wrapper'>
                <div className='toolkit__backgroundVideo__title'>
                    <h1 className='editH1'>Toolkitweek: Prototype Evening</h1>
                    <h2 className='editH2'>Lars Marcoen & Otto Van Sloun | 31.01.2025</h2>
                </div>
            </div>
            <video src={video} width="100%" height="auto" loop autoPlay muted playsInline></video>
        </div>

        <div className='toolkit__container'>
            <div className='toolkit__container__title'>
                <h1 className='editH1'>Toolkitweek: <br /> Prototype Evening</h1>
                <h2 className='editH2'>Lars Marcoen & Otto Van Sloun | 31.01.2025</h2>
            </div>

            <div className='toolkit__container__intro'>
                <div className='toolkit__intro__img'>
                    <img src={img1} alt="" />
                    <p className='credit__margin'> @Lars Marcoen </p>
                </div>

                <div className='toolkit__intro__text'>
                    Club Dérive was invited by LUCA School of Arts Campus C-mine Genk to host a Toolkitweek for students of the 2nd and 3th Bachelor. To go beyond the school walls, we collaborated with C-mine (the cultural institution). Together, we managed to set-up the PROTOTYPE EVENING; an experimental evening melting together different Toolkits and Masterclasses. 
                    <br /><br />
                    The focus of the week was to approach the location with an InSitu attitude. This was realised by exploring the space and playing with the accessible materials. By activating the outcome, immediate feedback was collected.  
                </div>
            </div>

            <div className='toolkit__container__bigImg'>
                <img className='toolkit__big__img' src={img2} alt="" />
                <p className='credit__margin'> @Lars Marcoen </p>
            </div>

            <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle' id='get__H2'>
                <h2 id='get__H2'>I. Exploring</h2>
                    SPACE <br />
                    The Vide (French for ‘void’), a post-industrial space that has been opened up by C-mine, provides a tight framework - an almost pre-defined club. Industrial features and elements define the spaces - no need to fight them with our own interventions. Our additions should be minimal, discrete, designed and should not alter but enhance the space. Quickly, we could observe that the spatial interventions needed to be big but subtle creating an almost unconscious impact.
                    <br /><br />
                    A heavy concrete dungeon separates a bigger space in two parts and brings us the gift of a secret passageway to a very small and narrow room. What can we present there? It’s only for the bold & brave! The other room is big with a high roof and is connected to a level with machinery. 
                    <br /><br />
                    It is our job to find traces of a certain presence from which the process of designing commences. Using the trace as a key, as a beginning to project something, to make a project… The site where any project is to be built could never be a tabula rasa.
                    <br /><br />
                    Each project holds a new place and new people to work with, new visions and new backgrounds, but we never start with nothing. There are always traces to be found: traces of symbols, stories, earlier interventions, work, communities,.... 
                    <br /><br />
                    The space is discovered through different senses: touch, sight, feel, sound,... with different tools like cameras, 3D scanners,...
                </div>
            </div>

            <div className='toolkit__container__swiper'>
                <Swiper
                    spaceBetween={20}
                    slidesPerView={3}
                    centeredSlides={true}
                    initialSlide= "1"
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                    >
                    <SwiperSlide><img src={img27} alt="" /></SwiperSlide>
                    <SwiperSlide><img src={img28} alt="" /></SwiperSlide>
                    <SwiperSlide><img src={img29} alt="" /></SwiperSlide>
                </Swiper>
            </div>

            <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle' id='get__H2'>
                    MATERIALS <br />
                    Is it possible to construct prototypes out of locally sourced recup materials? The C-mine site is a (gold)mine for urban miners and designers - we have gained access to LUCA School of Arts and C-mine’s arsenal of materials - mostly old scenographies and event elements. To create an overview we are challenged to inventarise everything. Can we set up a material library to taste from? We make a collection of materials, connectors & products. 
                    <br /><br />
                    Gathering the materials made it real - working hands-on to a 1:1 scale activates a new motivation. The hunt began at the C-mine site, visiting LUCA School of Arts and all C-mine spaces, images and dimensions were documented, added to the Cloud - a living inventory. By creating a digital and physical inventory, we got a tangible and mental overview of the accessible materials, tools and connectors. In the end it’s a rich and diverse surprising collection, enabling the students to connect and disconnect the mapped dots
                </div>
            </div>

            <div className='toolkit__container__bigImg'>
                <img className='toolkit__big__img' src={img23} alt="" />
                <p className='credit__margin'> @Lars Marcoen </p>
            </div>

            <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle' id='get__H2'>
                    <h2 id='get__H2'>II. Playing</h2>
                    What does this space need? Collectively it was decided to implement eleven interventions. Under the guidance of Verloren Bekisting, these interventions came to life. The form and materiality was shaped by the space and the predetermined inventory. Almost every intervention made a functional and aesthetic impact in the space. Verloren Bekisting introduced a unique approach to adapt the materials. ‘Easy’ techniques like stacking, clamping, connecting, bending, cutting and painting accelerate the construction process.  People without a background in design or experience with physical mediums managed to prototype from the get go using basic connections like zip ties, tension straps and construction clamps.
                </div>
            </div>

            <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle_img' id='get__H2'>
                    <img src={img24} alt="" />
                    <img src={img25} alt="" />
                </div>
            </div>

            <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle' id='get__H2'>
                    Our work became performative. The space made us reflect on the days of the active mine, how did miners work here? How did they move past the machines? Which psychogeographic layer do we add? Guy Debord's words 'travaillez j'amais' echo in our shell. We are unable to not work, we always work. But, our work feels recreational, work becomes leisure and leisure becomes work (Wark, 2025). 
                    <br /><br />
                    How could this be visualised? Anton Ripon GPS tracked our xyz-coordinates and mapped our progress and movement. Rather than tracking the visitor during the night, we tracked the work during the buildup and creation. Using the students' phones and an online server, 7 paths were drawn that are a testimony of this busy moment where prototypes emerge and disappear. This animated map was a first try of using collective GPS tracking as a tool to archive a situation. What could be the dynamic between the visible and invisible? The relationship between the labour and the space? What is the size of the unseen negotiations behind the outcome result? The emerged map was projected during the activations and is now part of the data collection of the Site Drawing research project he is leading.
                    <br /><br />
                    The implemented scenography and architectural interventions don’t imply certain behavior, but aims to open up the possibilities of approach, use and definition. Unfinishedness enables clubbers to define the elements and interventions, receiving ownership of the space.
                </div>
            </div>

            <div className='toolkit__container__bigImg'>
                <video className='toolkit_video' src={video2} width="100%" height="auto" loop autoPlay muted playsInline></video>
                <p className='credit__margin'> @Anton Ripon </p>
            </div>


            <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle' id='get__H2'>
                    <h2 id='get__H2'>III. Activating</h2>
                    How can the space of the night facilitate encounters and connections between people and disciplines?
                    <br /><br />
                    The space that was created could be seen as a living laboratory where art, technology and performance blend. The disciplines meet in an experimental, intuitive and spontaneous way with direct feedback from the audience. 
                    <br /><br />
                    It’s a rough, experimental playground. Everyone is a participant, and every art form – from music and light to fashion and scenography – becomes part of a collective experience. 
                    Creation does not stop at 5:00 PM. In nocturnal space, the boundary between work and leisure fades: artists and makers who work on their projects during the day test them at night in an unfiltered setting.
                </div>
            </div>

            <div className='toolkit__container__bigImg'>
                <img className='toolkit__big__img' src={img26} alt="" />
                <p className='credit__margin'> @Lars Marcoen </p>
            </div>

            <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle' id='get__H2'>
                    By inviting other artists and their mediums, our club night became a melting pot of disciplines. 
                    <br /><br />
                    <ul>
                        <li>
                            Recare about shoes is a VLAIO project with the mission to extend the life of shoes through maintenance, repair and reuse. By upcycling old shoes through techniques of cutting, gluing, stitching and hacking they turned into new valuable creations. 
                        </li>
                        <li>
                            The Masterclass by Johanna Seelemann used the steel industry in Genk as a case study. Can the knowledge and work of steel producers still be considered “craft”? What chain of actions is set in motion from design to execution? The result is an analysis of the past of the steel industry by transforming these insights into possible futures. 
                        </li>
                        <li>
                            The video of Jonathan Cant compliments the questions of how spaces of labour are turning into spaces of leisure, with abandoned factories turning into clubs. His work echoes a couple central questions which we reflect on: how does leisure-time interact with labour-time within the context of nightlife? Could we frame the construction and maintenance of collective identities through music as labour? Can we find ways of moving beyond the exploitative practices these post-industrial settings still echo?
                        </li>
                        <li>
                            The collective tracking by Anton Ripon was molded into an animation showing our movement during the ‘work’. 
                        </li>
                        <li>
                            The students from Alexandre Saunier's toolkit workshop on sound design with AI presented the results of their work during a 30-minute listening session that launched the evening. By combining traditional sound editing and mixing techniques with Neutone's neural synthesizers and Audiostellar's AI-driven sampler, they created original soundtracks ranging from ambient soundscapes to electronic music hits.
                        </li>
                        <li>
                            Music was provided by local legends - E2kie B2B Exchieve - Dj Swaggerlife - Oskaar - IIC
                        </li>
                    </ul>
                </div>
            </div>

            <div className='toolkit__container__swiper'>
                <div className='toolkit__container__swiper_s'>
                    <Swiper
                        modules={[Navigation, Pagination]}
                        spaceBetween={20}
                        slidesPerView={3}
                        loop={true}
                        centeredSlides={true}
                        keyboard={{
                            enabled: true,
                        }}
                        mousewheel={true}
                        grabCursor={true}
                        navigation
                        onSwiper={(swiper) => console.log(swiper)}
                        onSlideChange={() => console.log('slide change')}
                        >
                        <SwiperSlide><img src={img3} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img4} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img5} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img6} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img7} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img8} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img9} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img10} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img11} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img12} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img13} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img14} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img15} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img16} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img17} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img18} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img19} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img20} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img21} alt="" /></SwiperSlide>
                        <SwiperSlide><img src={img22} alt="" /></SwiperSlide>
                    </Swiper>

                </div>
            </div>

            <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle' id='get__H2'>
                    We can’t express our gratitude enough to the participants: <br />
                    Lucy Ballet, Stijn Boelen, Lorenzo Daenen, Mirko De Pessemier, Endre Dexters, Maryse Dierckx, Yuzef Dzhambekov, Toon Engelen, Marten Geuens, Lotte Maleszka, Lukas Mommeyer, Laurens-Jan Tans, Wesse Thijs, Pieter Vande Winkel, Sebastjaan Vanderhallen & Otto Van Sloun
                    <br /><br />
                    Contributors: <br />
                    Ron Barten (Verloren Bekisting), Laurens De Munck (Verloren Bekisting), Anton Ripon, Alexandre Saunier, Jonathan Cant, Johanna Seelemann, Ben Hagenaars (Recare about shoes), Lola Ilegems (Recare about shoes)
                    <br /><br />
                    Facilitators: <br />
                    Maureen Cordens (C-mine), Natalie Martens (C-mine), Nathalie Vaes (LUCA School of Arts) & technical team of C-mine! 
                </div>
            </div>

            {/* <div className="toolkit__container__body">
                <div className='toolkit__body__text__middle middle__center' id='get__H2'>
                    <div className='display__flex'>
                    <div className='article__download__button'>
                        <a href={downloadArticle} download="article_take-two-give-one_Lars-Marcoen_Bjorne-Baeten_Jade-Corbey"> Download Article</a>
                    </div>
                    </div>
                </div>
            </div> */}

        </div>
    </div>
   
  )
}

export default Toolkit