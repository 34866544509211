import React, { useEffect, Suspense, useRef } from 'react';
import * as THREE from 'three';
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';

import obj from '../assets/models/www-pc2.glb';

// Loader component om te tonen tijdens het laden
const Loader = () => (
  <div style={{ color: 'white', textAlign: 'center' }}>
    <h1>Model Laden...</h1>
  </div>
);

function Render7() {
  const canvasRef = useRef();

  useEffect(() => {
    const canvas = canvasRef.current;

    // SCENE
    const scene = new THREE.Scene();

    // LIGHTS
    const light = new THREE.DirectionalLight(0xffffff, 4);
    light.position.set(0, 3, 20);
    scene.add(light);

    const light2 = new THREE.DirectionalLight(0xffffff, 2);
    light2.position.set(0, 3, -20);
    scene.add(light2);

    // CAMERA
    const sizes = {
      width: window.innerWidth - 600,
      height: window.innerHeight,
    };

    const camera = new THREE.PerspectiveCamera(75, sizes.width / sizes.height, 0.1, 100);
    camera.position.set(0, 0, 3);
    scene.add(camera);

    // RENDERER
    const renderer = new THREE.WebGLRenderer({
      canvas: canvas,
      alpha: true,
    });
    renderer.setSize(sizes.width, sizes.height);
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    renderer.shadowMap.enabled = true;
    renderer.gammaOutput = true
    renderer.setClearColor(0x000000,0);


    // CONTROLS
    const controls = new OrbitControls(camera, canvas);
    controls.enableDamping = true;
    controls.enableZoom = false;

    // **RODE BAL ALS PLACEHOLDER**
    const placeholderGeometry = new THREE.SphereGeometry(0.5, 32, 32); // Maak een bol met radius 0.5
    const placeholderMaterial = new THREE.MeshStandardMaterial({ color: 0xff0000 }); // Rood materiaal
    const placeholder = new THREE.Mesh(placeholderGeometry, placeholderMaterial);
    placeholder.position.y = 0;
    scene.add(placeholder);

    // DRACO LOADER
    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/');

    // MODEL LOADER (GLTFLoader)
    const gltfLoader = new GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);

    gltfLoader.load(
      obj,
      (gltf) => {
        const model = gltf.scene;
        model.position.y = -2.8;
        model.scale.set(5.7, 5.7, 5.7);
        scene.add(model);

        // Verwijder de placeholder na het laden van het model
        scene.remove(placeholder);

        // Animatie toevoegen aan het model
        function animateModel() {
          requestAnimationFrame(animateModel);
          model.rotation.y += 0.001;
          renderer.render(scene, camera);
        }
        animateModel();
      },
      (xhr) => {
        console.log((xhr.loaded / xhr.total) * 100 + '% geladen');
      },
      (error) => {
        console.error('Fout bij het laden van het model', error);
      }
    );

    // VENSTER GROOTTE AANPASSEN
    window.addEventListener('resize', () => {
      sizes.width = window.innerWidth - 300;
      sizes.height = window.innerHeight;

      camera.aspect = sizes.width / sizes.height;
      camera.updateProjectionMatrix();
      renderer.setSize(sizes.width, sizes.height);
      renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    });

    // ANIMATIE LOOP
    function animate() {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    }
    animate();

    return () => {
      // Cleanup event listeners en resources bij ontkoppeling van de component
      window.removeEventListener('resize', animate);
    };
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <canvas className='webgl7' ref={canvasRef}></canvas>
    </Suspense>
  );
}

export default Render7;
